import React from 'react';
import styled from 'styled-components';
import { Textfit } from 'react-textfit';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Plx from 'react-plx';

const sectionPlx = [
    {
      start: 0,
      end: 300,
      properties: [
        {
          startValue: -50,
          endValue: 0,
          property: "translateY"
        }
      ]
    },
]
const morePlx = [
    {
      start: 'self',
      duration: 300,
      properties: [
        {
            startValue: 0.9,
            endValue: 1,
            property: "scale"
          }
      ]
    },
]


const Wrapper = styled.section`
    display: flex;
    background: ${props => props.theme.white};
    max-width: 1300px;
    width: 90%;
    margin: 20px auto 0 auto;
    border-radius: 10px;
    overflow: hidden;
    ${props => props.left && `
        flex-direction: row-reverse;
    `}
    @media (max-width: 850px) {
        flex-direction: column;
        height: auto;
    }
`
const ImgElem = styled.div`
    width: 60%;
    padding: 20px;
    box-sizing: border-box;
    @media (max-width: 850px) {
        padding: 10px;
        width: 100%;
        height: 50%;
        padding-bottom: 10px;
    }
`
const Elem = styled.div`
    width: 40%;
    padding: 20px;
    @media (max-width: 850px) {
        width: 100%;
        padding: 10px;
        height: auto;
        padding-top: 0;
    }
`
const Inner = styled.div`
    background: ${props => props.color};
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
`

const H1 = styled.h1`
    color: #fff;
    font-size: 2em;
    text-align: center;
    margin: 0;
`
const P = styled.p`
    font-size: 22px;
    text-align: center;
    color: ${props => props.theme.fg};
    padding: 10px;
`
const LinkWrap = styled(Plx)`
    align-self: center;
    padding: 10px;
`

const More = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    border: solid 3px #fff;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: inline;
    font-size: 1.2em;
    font-family: ${props => props.theme.bold};
    font-weight: 700;
    &:hover {
        background: #fff;
        color: ${props => props.color};
    }
`

const Section = ({ section, index }) => {

    const image = getImage(section.sektionImg);

    return (
        <Plx parallaxData={sectionPlx}>
            <Wrapper left={index % 2 !== 0}>
                <ImgElem>
                    <GatsbyImage image={image} />
                </ImgElem>
                <Elem>
                    <Inner color={section.farbe}>
                        <H1><Textfit mode='single'>{section.title}</Textfit></H1>
                        <P>{section.subtitle}</P>
                        <LinkWrap parallaxData={morePlx}>
                            <More to={section.link} color={section.farbe}>Mehr erfahren</More>
                        </LinkWrap>
                    </Inner>
                </Elem>
            </Wrapper>
        </Plx>
    )
};

export default Section;