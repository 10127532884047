import * as React from "react";
import { graphql } from "gatsby";
import SiteWrapper from "../siteWrapper";
import Header from "../components/home/header";
import Section from "../components/home/section";
import Aktionen from "../components/home/aktionen";
import Helmet from "react-helmet";

const IndexPage = (props) => {
  console.log(props, "props");

  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const renderSections = () => {
    return data.alleSektionen.map((section, index) => (
      <Section key={index} section={section} index={index} />
    ));
  };

  return (
    <SiteWrapper>
      <Helmet>
        <title>KjG St. Barbara / Littenweiler - Home</title>
        <meta
          name="description"
          content="Die KjG St. Barbara aus Freiburg-Littenweiler bietet Sommerlager (Zeltlager), wöchentliche Gruppenstunden und andere coole Aktionen für Jugendliche und Kinder. Schau rein, was wir zu bieten haben!"
        />
        <meta
          name="keywords"
          content="KjG, Littenweiler, Freiburg, St. Barbara, Sommerlager, Zeltlager, Jugendfreizeit, Kinderfreizeit"
        />
      </Helmet>
      <Header data={data} />
      {renderSections()}
      <Aktionen data={data.alleAktionen} />
    </SiteWrapper>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "sites" }, name: { eq: "home" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              subtitle
              alleAktionen {
                datum(formatString: "DD.MM.YYYY")
                title
              }
              headerImg {
                childImageSharp {
                  gatsbyImageData(width: 1920)
                }
              }
              logo {
                childImageSharp {
                  gatsbyImageData(width: 100)
                }
              }
              alleSektionen {
                farbe
                link
                sektionImg {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                subtitle
                title
              }
            }
          }
        }
      }
    }
  }
`;
