import React from 'react';
import styled from 'styled-components';
import Plx from "react-plx";

const itemParallax = [
    {
      start: 'self',
      duration: 300,
      properties: [
        {
          startValue: 50,
          endValue: 0,
          property: "translateX"
        },
        {
          startValue: 90,
          endValue: 0,
          property: "translateY"
        },
        {
          startValue: 0.7,
          endValue: 1,
          property: "opacity"
        }
      ]
    },
]
const headingParallax = [
    {
      start: 'self',
      duration: 300,
      properties: [
        {
          startValue: 0.5,
          endValue: 1,
          property: "scale"
        }
      ]
    },
]

const Wrapper = styled.section`
    padding: 20px;
`
const H1 = styled.h1`
    color: #111;
    font-size: 3.5em;
`
const Container = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 30px;
    width: 90%;
    margin: auto;
    @media (max-width: 1000px) {
        grid-template-columns: auto auto;
    }
    @media (max-width: 680px) {
        grid-template-columns: auto;
    }
`
const Item = styled(Plx)`
    padding: 30px 10px;
    border-radius: 15px;
    background: #fefefe;
    wordBreak: 'break-all';
`
const Title = styled.h1`
    font-size: 2em;
    margin: 0;
    overflow-wrap: anywhere;
`
const Date = styled.h2`
    font-family: ${props => props.theme.normal};
    margin: 0;
`

const Aktionen = ({ data }) => {

    const renderItems = () => {
        const arr = [];
        data.forEach(aktion => {
            arr.push(
                    <Item key={aktion.datum} parallaxData={itemParallax}>
                        <Title>{aktion.title}</Title>
                        <Date>{aktion.datum}</Date>
                    </Item>
            )
        })
        return arr;
    }

    return (
        <Wrapper>
            <Plx parallaxData={headingParallax}><H1>Aktionen</H1></Plx>
            <Container>
                {renderItems()}
            </Container>
        </Wrapper>
    )
};

export default Aktionen;