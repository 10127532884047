import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Plx from "react-plx";

const logoParallax = [
    {
      start: 0,
      end: 300,
      properties: [
        {
          startValue: 1,
          endValue: 3,
          property: "scale"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        },
        {
          startValue: 1,
          endValue: -70,
          property: "translateY"
        }
      ]
    },
]

const textParallax = [
    {
      start: 0,
      end: 300,
      properties: [
        {
          startValue: 1,
          endValue: 130,
          property: "translateY"
        },
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    },
]

const Outer = styled.div`
    display: grid;
    width: 100%;
    height: 80vh;
`
const Wrapper = styled.div`
    grid-area: 1/1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: ${props => props.theme.fjalla};
`
const H1 = styled.h1`
    margin: 10px 0 0 0;
    color: #fff;
    font-size: 2.8em;
    font-family: ${props => props.theme.bold};
`
const H2 = styled.h2`
    margin: 0; 
    color: #fff;
    font-size: 2.5em;
`
const Image = styled(GatsbyImage)`
    max-width: 100px;
    margin-top: 20px;
    opacity: 0.7;
`
const BGImage = styled(GatsbyImage)`
    grid-area: 1/1;
`
const Heading = styled.div`
    text-align: center;
`

const Header = ({ data }) => {
    const logo = getImage(data.logo);
    const bg = getImage(data.headerImg);
    return (
        <Outer>
            <BGImage image={bg} alt="Background Image" />
            <Wrapper bg={bg}>
                <Plx parallaxData={textParallax}>
                    <Heading>
                        <H1 className='animate__animated animate__fadeInRight animate__delay-1s'>{data.title}</H1>
                        <H2 className='animate__animated animate__fadeInRight animate__delay-1s'>{data.subtitle}</H2>
                    </Heading>
                </Plx>
                <Plx parallaxData={logoParallax}>
                    <Image image={logo} alt='KjG Seelenbohrer' className='animate__animated animate__fadeInLeft animate__delay-1s' />
                </Plx>
            </Wrapper>
        </Outer>
    )
};

export default Header;
